"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const CollapseAll = createSvgIcon(
  <path d="M8.89911 19.3078L8.19141 18.6001L11.9991 14.7924L15.8068 18.6001L15.0991 19.3078L11.9991 16.2078L8.89911 19.3078ZM11.9991 9.20778L8.19141 5.40008L8.89911 4.69238L11.9991 7.79238L15.0991 4.69238L15.8068 5.40008L11.9991 9.20778Z" />,
  "CollapseAll",
)

const CollapseAllIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <CollapseAll sx={{ fill: _fill, ...sx }} {...rest} />
}

export default CollapseAllIcon
