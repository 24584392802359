"use client"

import dayjs from "dayjs"
import { debounce } from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"

/**
 * useIdle hook to detect user idle state and elapsed time
 * @param timeout seconds to wait before user is considered idle
 * @returns {isIdle, elapsed} isIdle is a boolean value to indicate if the user is idle, elapsed is the time in milliseconds since the user was last active
 */
const seconds = 1000
const setLocalStorage = debounce((timeout: number) => {
  localStorage.setItem("timeout", dayjs().add(timeout, "s").toISOString())
}, 1 * seconds)
export const useIdle = (timeout: number, timeLeft = 0) => {
  const [isIdle, setIsIdle] = useState(false)
  const [elapsed, setElapsed] = useState(0)
  const alertIntervalRef = useRef<NodeJS.Timeout>()
  const idleRef = useRef<NodeJS.Timeout>()
  const idleTimeout = useCallback(() => {
    if (idleRef.current) {
      clearTimeout(idleRef.current)
    }
    idleRef.current = setTimeout(() => {
      setIsIdle(true)
      clearInterval(alertIntervalRef.current)
    }, timeout * seconds)
  }, [timeout])

  const alertTimeout = useCallback(() => {
    if (alertIntervalRef.current) {
      clearInterval(alertIntervalRef.current)
    }
    alertIntervalRef.current = setInterval(() => {
      const _timeLeft = dayjs(localStorage.getItem("timeout")).diff(
        dayjs(),
        "s",
      )
      if (_timeLeft <= timeLeft) {
        setElapsed(_timeLeft)
      }
    }, seconds)
  }, [timeout])

  useEffect(() => {
    const handleEvent = () => {
      setLocalStorage(timeout)
      if (timeLeft > 0) {
        alertTimeout()
      }
      idleTimeout()
    }
    window.addEventListener("mousemove", handleEvent)
    window.addEventListener("keydown", handleEvent)
    return () => {
      window.removeEventListener("mousemove", handleEvent)
      window.removeEventListener("keydown", handleEvent)
    }
  }, [timeout])
  return { isIdle, elapsed }
}
