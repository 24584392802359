"use Client"
import { List } from "@mui/material"
import { MenuIcons } from "@tc/ui-dfe/utils/constants"
import type { Route } from "@tc/ui-permission/utils"
import { usePathname } from "next/navigation"
import { MenuItem } from "./MenuItem"
interface ReduceChildRoutesProps {
  list: React.ReactNode[]
  item: Route
  path: string
  onClose?: () => void
}
interface MenuProps {
  routes: readonly Route[]
  onClose?: () => void
}
const reduceChildRoutes = ({
  list,
  item,
  path,
  onClose,
}: ReduceChildRoutesProps) => {
  const key = `${item.path}`
  const partialMatch =
    path.substring(1).split("/", 2).includes(item.path.substring(1)) &&
    path.substring(1).split("/", 2).at(0) === item.path.split("/").at(1) // this will check if the first path is the same and check if all other subpaths are in the item.path
  const exactMatch = path === item.path

  list.push(
    <MenuItem
      key={key}
      active={exactMatch || partialMatch}
      label={item.label}
      path={item.path}
      icon={MenuIcons[item.path as keyof typeof MenuIcons]}
      open={partialMatch}
      onClose={onClose}
      access={item.access}
    />,
  )
  return list
}
export const Menu = ({ routes, onClose }: MenuProps) => {
  const path = usePathname()
  return (
    <List component="div" data-testid="test-menu">
      {routes.reduce(
        (list: React.ReactNode[], item: Route) =>
          reduceChildRoutes({ list, item, path, onClose }),
        [],
      )}
    </List>
  )
}
