"use client"
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
  PaperProps,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { CloseIcon, CollapseAllIcon, ExpandAllIcon } from "@tc/ui-dfe/icons"
import React, { useEffect, useState } from "react"

export interface ModalProps extends DialogProps {
  children?: React.ReactNode
  header?: string
  actions?: React.ReactNode
  onSubmit?: (event: React.FormEvent) => void
  contentProps?: DialogContentProps
  paperProps?: PaperProps
  onFullScreen?: (isFullScreen: boolean) => void
  disableCloseButton?: boolean
  disableFullScreenButton?: boolean
}

export const Modal = (props: ModalProps) => {
  const {
    children,
    header,
    open,
    actions,
    maxWidth = "md",
    fullWidth = true,
    onClose,
    onSubmit = () => {},
    contentProps,
    paperProps,
    onFullScreen,
    disableCloseButton = false,
    disableFullScreenButton = false,
    ...rest
  } = props
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const [fullScreen, setFullScreen] = useState(mdDown)
  const toggleFullWidth = (isFull?: boolean) => {
    const isFullScreen = isFull ?? !fullScreen
    setFullScreen(isFullScreen)
    onFullScreen?.(isFullScreen)
  }
  useEffect(() => {
    toggleFullWidth(mdDown)
  }, [mdDown])
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: {
          width: "100%",
        },
        ...paperProps,
      }}
      {...rest}
    >
      <DialogTitle sx={{ py: 0 }}>
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar disableGutters sx={{ px: 0 }}>
            <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
              {header}
            </Typography>
            <Stack direction="row" spacing={1}>
              {!disableFullScreenButton && (
                <IconButton
                  onClick={() => toggleFullWidth()}
                  size="small"
                  data-testid="fullScreenBtn"
                  sx={{
                    color: "secondary.light",
                    transform: "rotate(45deg)",
                  }}
                >
                  {fullScreen ? (
                    <CollapseAllIcon data-testid="FullscreenExitIcon" />
                  ) : (
                    <ExpandAllIcon data-testid="FullscreenIcon" />
                  )}
                </IconButton>
              )}
              {!disableCloseButton && (
                <IconButton
                  onClick={(e) => onClose?.(e, "escapeKeyDown")}
                  size="small"
                  data-testid="closeBtn"
                  sx={{
                    color: "secondary.light",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      </DialogTitle>

      <DialogContent {...contentProps}>{children}</DialogContent>

      {actions && (
        <DialogActions sx={{ px: 3, pb: 3 }}>{actions}</DialogActions>
      )}
    </Dialog>
  )
}
