"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Logout = createSvgIcon(
  <path d="M20.5923 12.5H9.15385V11.5H20.5923L18.5038 9.41155L19.2115 8.6923L22.5193 12L19.2115 15.3077L18.5038 14.5885L20.5923 12.5ZM15.4038 8.73078V5H5V19H15.4038V15.2692H16.4038V20H4V4H16.4038V8.73078H15.4038Z" />,
  "Logout",
)

const LogoutIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <Logout sx={{ fill: _fill, ...sx }} {...rest} />
}

export default LogoutIcon
