"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const External = createSvgIcon(
  <path d="M6.18848 17.2885L5.5 16.6L15.5808 6.5H6.28845V5.5H17.2885V16.5H16.2885V7.2077L6.18848 17.2885Z" />,
  "External",
)

const ExternalIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <External sx={{ fill: _fill, ...sx }} {...rest} />
}

export default ExternalIcon
