import { useState } from "react"
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
} from "@mui/material"
import {
  ExternalIcon,
  FeedbackIcon,
  HelpIcon,
  LogoutIcon,
} from "@tc/ui-dfe/icons"
import { signOut } from "next-auth/react"
import { Link } from "../Link"
import { FeedbackModal } from "../Feedback"

interface SidebarFooterItemProps {
  dataTestId?: string
  icon: React.ReactNode
  label: string
  title?: string
  onClick?: () => void
}
const SidebarFooterItem = (props: SidebarFooterItemProps) => {
  const { dataTestId, icon, label, title, onClick } = props
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  return (
    <ListItemButton
      data-testid={dataTestId}
      sx={{
        minHeight: {
          xs: 68,
          sm: 48,
        },
        p: 0,
      }}
      divider={smDown ? true : false}
      onClick={onClick}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: 1,
          justifyContent: "center",
        }}
      >
        {icon}
      </ListItemIcon>

      <ListItemText
        title={title}
        primary={label}
        primaryTypographyProps={{
          sx: {
            lineHeight: 2,
          },
        }}
        sx={{
          flexGrow: {
            xs: 1,
            sm: 0,
          },
        }}
      />
    </ListItemButton>
  )
}
export const SidebarFooter = ({ logoutUrl }: { logoutUrl: string }) => {
  const [isOpenFeedbackModal, setOpenFeedbackModal] = useState(false)
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true)
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false)

  return (
    <List component={"div"}>
      <Link
        data-testid="testExternalLinkItem"
        href="https://www.mpi.govt.nz/"
        external
        custom
      >
        <SidebarFooterItem
          icon={<ExternalIcon fontSize="small" />}
          label="mpi.govt.nz"
        />
      </Link>
      <SidebarFooterItem
        data-testid="testFeedbackItem"
        icon={<FeedbackIcon fontSize="small" />}
        label="Give us feedback"
        onClick={handleOpenFeedbackModal}
      />
      <Link
        data-testid="testHelpLinkItem"
        href="https://www.mpi.govt.nz/export/exporting-from-nz-how-it-works/mpis-role-in-exporting/replacing-our-trade-certification-systems/"
        external
        custom
      >
        <SidebarFooterItem
          data-testid="testHelpItem"
          icon={<HelpIcon fontSize="small" />}
          label="Help"
        />
      </Link>
      {mdDown && (
        <SidebarFooterItem
          data-testid="testLogoutItemMd"
          icon={<LogoutIcon fontSize="small" />}
          label="Logout"
          onClick={() => signOut({ callbackUrl: logoutUrl })}
        />
      )}
      <FeedbackModal
        isOpen={isOpenFeedbackModal}
        onClose={handleCloseFeedbackModal}
      />
    </List>
  )
}
