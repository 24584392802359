"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Feedback = createSvgIcon(
  <g>
    <path
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      d="M8.90208 12.7721C9.40054 12.7721 9.80415 12.375 9.80415 11.8854C9.80415 11.3964 9.40054 11 8.90208 11C8.40362 11 8 11.3964 8 11.8854C8 12.375 8.40362 12.7721 8.90208 12.7721Z"
    />
    <path
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      d="M12.4255 12.7721C12.924 12.7721 13.3276 12.375 13.3276 11.8854C13.3276 11.3964 12.924 11 12.4255 11C11.9271 11 11.5234 11.3964 11.5234 11.8854C11.5234 12.375 11.9271 12.7721 12.4255 12.7721Z"
    />
    <path
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      d="M15.947 12.7721C16.4455 12.7721 16.8491 12.375 16.8491 11.8854C16.8491 11.3964 16.4455 11 15.947 11C15.4485 11 15.0449 11.3964 15.0449 11.8854C15.0449 12.375 15.4485 12.7721 15.947 12.7721Z"
    />
    <path d="M2.46094 21.5385L4.02634 16.2231C3.70967 15.5526 3.45935 14.8653 3.27536 14.1613C3.0914 13.4572 2.99941 12.7368 2.99941 12C2.99941 10.755 3.23557 9.585 3.70789 8.49C4.18022 7.395 4.82124 6.4425 5.63094 5.6325C6.44064 4.8225 7.39278 4.18125 8.48736 3.70875C9.58196 3.23625 10.7515 3 11.9961 3C13.2406 3 14.4107 3.23616 15.5063 3.70847C16.6019 4.18081 17.555 4.82182 18.3654 5.63152C19.1759 6.44122 19.8175 7.39337 20.2903 8.48795C20.763 9.58255 20.9994 10.7521 20.9994 11.9966C20.9994 13.2412 20.7632 14.4113 20.2907 15.5069C19.8182 16.6025 19.1769 17.5556 18.3669 18.366C17.5569 19.1765 16.6044 19.8181 15.5094 20.2909C14.4144 20.7636 13.2444 21 11.9994 21C11.2626 21 10.5422 20.908 9.83816 20.7241C9.13413 20.5401 8.44685 20.2897 7.77634 19.9731L2.46094 21.5385ZM3.94941 20.05L7.14941 19.1C7.40838 19.0333 7.64588 19.0019 7.86191 19.0058C8.07795 19.0096 8.30711 19.0744 8.54941 19.2C9.08275 19.4667 9.64108 19.6667 10.2244 19.8C10.8077 19.9333 11.3994 20 11.9994 20C14.2327 20 16.1244 19.225 17.6744 17.675C19.2244 16.125 19.9994 14.2333 19.9994 12C19.9994 9.76667 19.2244 7.875 17.6744 6.325C16.1244 4.775 14.2327 4 11.9994 4C9.76608 4 7.87441 4.775 6.32441 6.325C4.77441 7.875 3.99941 9.76667 3.99941 12C3.99941 12.6 4.06608 13.1917 4.19941 13.775C4.33275 14.3583 4.53275 14.9167 4.79941 15.45C4.91608 15.6667 4.98179 15.8958 4.99654 16.1375C5.01127 16.3792 4.9789 16.6167 4.89941 16.85L3.94941 20.05Z" />
  </g>,
  "Feedback",
)

const FeedbackIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary

  return <Feedback sx={{ fill: _fill, ...sx }} {...rest} />
}
export default FeedbackIcon
