"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const ExpandAll = createSvgIcon(
  <path
    d="M12.0004 20.2693L8.1543 16.4231L8.87352 15.7039L12.0004 18.8308L15.1274 15.7039L15.8466 16.4231L12.0004 20.2693ZM8.87352 8.31928L8.1543 7.60006L12.0004 3.75391L15.8466 7.60006L15.1274 8.31928L12.0004 5.19238L8.87352 8.31928Z"
    fill="#1E2C3F"
  />,
  "ExpandAll",
)

const ExpandAllIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <ExpandAll sx={{ fill: _fill, ...sx }} {...rest} />
}

export default ExpandAllIcon
