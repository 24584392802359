"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const CloseNav = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g>
      <path d="M9.33398 12L5.33398 8L9.33398 4L10.2673 4.93333L7.20065 8L10.2673 11.0667L9.33398 12Z" />
    </g>
  </svg>,
  "SmallCloseNav",
)
const SmallCloseNavIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <CloseNav sx={{ width: 16, height: 16, fill: _fill }} {...rest} />
}

export default SmallCloseNavIcon
