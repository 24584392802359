import { Stack } from "@mui/material"
import { Modal } from "../Modal"
import { Button } from "../Button"

export interface FeedbackModalProps {
  isOpen: boolean
  onClose?: () => void
}
export const FeedbackModal = (props: FeedbackModalProps) => {
  const { isOpen, onClose } = props

  return (
    <Modal
      data-testid="testFeedbackModal"
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      header="Give us feedback"
      actions={
        <Stack>
          <Button
            variant="contained"
            data-testid="testOkButton"
            ariaLabel={"Ok button"}
            onClick={onClose}
          >
            Ok
          </Button>
        </Stack>
      }
    >
      Please contact the team on futurecertification@mpi.govt.nz to share any
      feedback on MPI Trade Certification
    </Modal>
  )
}
