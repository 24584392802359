"use client"
import { createSvgIcon, useTheme } from "@mui/material"
import { get } from "lodash"
import type { IconProps } from "../types"

const Close = createSvgIcon(
  <path d="M6.39911 18.3078L5.69141 17.6001L11.2914 12.0001L5.69141 6.40008L6.39911 5.69238L11.9991 11.2924L17.5991 5.69238L18.3068 6.40008L12.7068 12.0001L18.3068 17.6001L17.5991 18.3078L11.9991 12.7078L6.39911 18.3078Z" />,
  "Close",
)

const CloseIcon = (props: IconProps) => {
  const { fill, sx, ...rest } = props
  const theme = useTheme()
  const _fill = (fill && get(theme.palette, fill)) || theme.palette.text.primary
  return <Close sx={{ fill: _fill, ...sx }} {...rest} />
}

export default CloseIcon
