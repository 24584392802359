import { forwardRef } from "react"

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material"

export interface ButtonProps extends MuiButtonProps {
  ariaLabel: string
  title?: string
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const { ariaLabel, title, children, sx, ...restProps } = props
    return (
      <MuiButton
        data-testid="testButton"
        ref={ref}
        aria-label={ariaLabel}
        title={title}
        sx={sx}
        {...restProps}
      >
        {children}
      </MuiButton>
    )
  },
)
Button.displayName = " Button"
